/**
 * Shared ActionCable helpers.
 */

import { createConsumer } from "@rails/actioncable"

export const consumer = createConsumer(() => {
  const meta = document.querySelector('meta[name="action-cable-url"]');
  return meta.content;
})
