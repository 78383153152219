import * as Phone from '@referralexchange/phone';
import { consumer } from '../../common/actioncable';

import './style.scss'

// Initialize the connection to our phone server
const phoneClient = new Phone.Client({
    portType:           Phone.PortType.EmbeddedWeb,
    // TODO: See if we can dynamically pass this through
    remoteExtensionID: 'ngjhphgjojadbfihoaleohdbnekniddo'
});

/**
 * initiate a new simpleOutboundCall through the phone extension.
 * @param {*} param0 
 */
const startSimpleOutboundCall = function(payload) {
    console.debug('Starting Simple Outbound call.', payload)
    phoneClient.startCall('simple_outbound', payload)
} 
window.startSimpleOutboundCall = startSimpleOutboundCall;

/** 
 * React to clicks on Click2call links. 
 */
function initializeLinkListener() {
    // listen for link clicks
    const elements = document.querySelectorAll('.click2call-link-v2')
    elements.forEach(e => {
        e.addEventListener('click', (event) => {
            event.preventDefault();

            const data = event.target.dataset;
            const { phoneNumber } = data;
 
            startSimpleOutboundCall({ To: phoneNumber })
        })
    })
}

/**
 * React to Click2call events pushed from the backend
 */
function listenToWebSocket() {
    consumer.subscriptions.create({
        channel: 'Phone::SimpleOutboundChannel',
        user_id: window.current_user_id, // figure out a better way to wire this through
    }, {
        received(payload) {
            console.log({ event: 'listenToWebSocket/startSimpleOutboundCall', ...payload });
            startSimpleOutboundCall(payload)
        }
    })
}

export function initializeClick2call() {
    initializeLinkListener();
    listenToWebSocket();
}
